import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';


import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { AdminRoutes, AuthRoutes, ErrorsRoutes, FULL_ROUTES } from './routes';
import { PagesTitleTexts } from './texts/pages-title.text';

export const routes: Routes = [
  {
    path: '',
    redirectTo: FULL_ROUTES.ADMIN_DASHBOARD,
    pathMatch: 'full',
  },
 {
    path: AuthRoutes.AUTH,
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: AdminRoutes.ADMIN,
    loadChildren: () => import('./modules/admin-panel/admin-panel.module').then(m => m.AdminPanelModule)
  },
  {
    path: ErrorsRoutes.ERROR_404,
    component: P404Component,
    data: {
      title: PagesTitleTexts.PAGE_404
    }
  },
  {
    path: ErrorsRoutes.ERROR_500,
    component: P500Component,
    data: {
      title: PagesTitleTexts.PAGE_500
    }
  },
  { path: '**', redirectTo: ErrorsRoutes.ERROR_404 }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
