import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';


// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SeoService } from './services/seo/seo.service';
import { TokenAndLangInterceptorService } from './services/interceptors/token-and-lang-interceptor.service';
import { PagePreloaderComponent } from './layout/parts/page-preloader/page-preloader.component';
import { NgProgressModule } from 'ngx-progressbar';
import { AlertModule } from '@full-fledged/alerts';
import { SNACKBAR_CONFIG } from './constants/app-constants';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgProgressModule,
    AlertModule.forRoot(SNACKBAR_CONFIG),
  ],
  declarations: [
    AppComponent,
    P404Component,
    P500Component,
    PagePreloaderComponent,
  ],
  providers: [
    SeoService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenAndLangInterceptorService,
      multi: true
    }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
