export enum PagesTitleTexts {
    PAGE_SIGN_IN = 'Sign In',
    PAGE_ACCOUNT = 'My Profile',
    CHANGE_PASSWORD = 'Change Password',
    PAGE_ADMIN_DASHBOARD = 'Dashboard',
    PAGE_ADD_CAR_FROM_OTHER_SITE = 'Add car from other site',
    PAGE_SEND_NOTIFICATION = 'Send Notification',
    PAGE_404 = '404 Not Found',
    PAGE_500 = 'Server Error'
}
