import { Component } from '@angular/core';
import { FULL_ROUTES } from '../../routes';

@Component({
  templateUrl: '404.component.html'
})
export class P404Component {

  public readonly fullRoutes = FULL_ROUTES;

  constructor() { }

}
