export enum ACCOUNT {
    USERS = '/users/',
    MANAGE_ACCOUNT_INFO = '/users/{id}/',
    CHANGE_PASSWORD_URL = '/auth-users/change-password/'
}

export enum AUTH {
    REGISTER = '/auth-users/register/',
    LOGIN = '/auth-users/admin-sign-in/',
    REFRESH_TOKEN = '/auth-users/refresh-token/',
    FORGOT_PASSWORD = '/auth-users/forgot-password/',
    RESET_PASSWORD_URL = '/auth-users/reset-password/{resetKey}/'
}

export enum ADVERT {
    ADD_CAR_IMAGES = '/images/',
    DELETE_CAR_IMAGES = '/images/{id}/',
    ORDER_IMAGES = '/order_advert_images/{id}/',
}

export enum ADMIN_PANEL {
    STATISTICS = '/simple-admin/statistics/',
    SEND_NOTIFICATION = '/simple-admin/send-notification/',
}


export enum ENTITY {
    LIST = '/simple-admin/{entity}/',
    CREATE = '/simple-admin/{entity}/',
    UPDATE = '/simple-admin/{entity}/{id}/',
    DELETE = '/simple-admin/{entity}/{id}/',
    DELETE_MULTIPLE = '/simple-admin/{entity}/multiple-delete/',
}

export enum CAR_SCRAPER {
    ADD_CAR = '/simple-admin/car-scraper/',
    RESULT = '/car-scraper-info/'
}

export enum CAR {
    LIST = '/car/'
}
