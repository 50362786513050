import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IRouterData } from '../../interfaces/router-data.inteface';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
      private readonly router: Router,
      private readonly activatedRoute: ActivatedRoute,
      private readonly titleService: Title
  ) {
      this.changePageTitle();
  }

  getRouteData(): Observable<IRouterData> {
      return this.router.events
          .pipe(
              filter(e => e instanceof NavigationEnd),
              map(() => this.activatedRoute.snapshot),
              map(route => {
                  while (route.firstChild) {
                      route = route.firstChild;
                  }
                  return route.data;
              })
          );
  }

  changePageTitle() {
      this.getRouteData().subscribe((data: IRouterData) => {
          if (data.title) {
              this.setPageTitle(data.title);
          }
      });
  }

  setPageTitle(title = '', MAIN = environment.siteName) {
      if (title) {
          this.titleService.setTitle(`${title} | ${MAIN}`);
      }
  }

}
