import { AlertConfig } from '@full-fledged/alerts';

export enum AppConstants {
    LANGUAGE_KEY = 'lang',
}

export const SNACKBAR_CONFIG: AlertConfig = {
    maxMessages: 5,
    timeout: 3 * 1000,
    positionX: 'right'
};

export enum LocalStorageConstants {
    ACCESS_TOKEN = 'token',
    REFRESH_TOKEN = 'refresh_token',
    USER_ID = 'id',
    OFFICIAL_ID = 'official_id'
}