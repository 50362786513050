import { Injectable } from '@angular/core';
import { FULL_ROUTES } from '../../routes';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BaseAPIService } from '../base-api.service';
import { AUTH } from '../api-endpoints';
import { tap } from 'rxjs/operators';
import { IUserLogin } from '../../interfaces/auth.interface';
import { LocalStorageConstants } from '../../constants/app-constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseAPIService {

  private readonly fullRoutes = FULL_ROUTES;
  private authToken: string;
  private refresh: string;
  public  id: any;

  constructor(
      public http: HttpClient,
      private readonly router: Router
  ) {
    super(http);
  }

  login(credentials: IUserLogin) {
    return this.post(AUTH.LOGIN, credentials);
  }

  refreshExpiredToken() {
    return this.post(AUTH.REFRESH_TOKEN, {
      refresh: this.refreshToken
    }).pipe(tap((res: any) => {
      this.setAuthToken(true, {...res});
    }));
  }

  setAuthToken(rememberMe: boolean, res) {
    this.authToken = res.access;
    this.refresh = res.refresh;
    if (rememberMe) {
      localStorage.setItem(LocalStorageConstants.ACCESS_TOKEN, res.access);
      localStorage.setItem(LocalStorageConstants.REFRESH_TOKEN, res.refresh);
    }
  }

  setUserId(rememberMe: boolean, id) {
    this.id = id;
    if (rememberMe) {
      localStorage.setItem(LocalStorageConstants.USER_ID, id);
    }
  }

  saveTokenAndUserId(rememberMe: boolean, res) {
    this.setAuthToken(rememberMe, res);
    this.setUserId(rememberMe, res.id);
  }

  get isLoggedIn(): boolean {
    // return !!localStorage.getItem('token') || this.cookieService.check('token') || !!this.token;
    return !!localStorage.getItem(LocalStorageConstants.ACCESS_TOKEN) || !!this.authToken;
  }

  get token(): string {
    // return localStorage.getItem('token') || this.cookieService.get('token') || this.token;
    return localStorage.getItem(LocalStorageConstants.ACCESS_TOKEN) || this.authToken;
  }

  get refreshToken(): string {
    return localStorage.getItem(LocalStorageConstants.REFRESH_TOKEN) || this.refresh;
  }

  get userId(): string {
    return localStorage.getItem(LocalStorageConstants.USER_ID) || this.id;
  }

  logOut(navigate = false) {
    // this.cookieService.delete(LocalStorageConstants.ACCESS_TOKEN);
    localStorage.removeItem(LocalStorageConstants.ACCESS_TOKEN);
    localStorage.removeItem(LocalStorageConstants.REFRESH_TOKEN);
    localStorage.removeItem(LocalStorageConstants.USER_ID);
    this.authToken = null;
    this.refresh = null;
    this.id = null;
    if (navigate) {
      return this.router.navigate([this.fullRoutes.SIGN_IN]);
    }
  }

}
