import { Component } from '@angular/core';
import { SeoService } from './services/seo/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(
      private readonly seoService: SeoService
  ) { }
}
