// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  siteName: 'admin.gear.am',
  apiHost: 'https://gear-apidev.codebnb.me/api/v1',
  // apiHost: 'http://127.0.0.1:8080/api/v1',
  // apiHost: 'https://api.gear.am/api/v1',
  appUrl: 'https://gear-admin.codebnb.me/',
  defaultLang: 'en',
  googleCaptcha: {
    siteKey: '6LfNbC0aAAAAAFCuUkUwmpAyScJnr4_mmW_LsjKu'
  }
};
