export enum ErrorsRoutes {
    ERROR_404 = '404',
    ERROR_500 = '500',
}

export enum AuthRoutes {
    AUTH = 'auth',
    SIGN_IN = 'sign-in',
}

export enum AccountRoutes {
    MY = 'profile',
    CHANGE_PASSWORD = 'change-password',
    ACCOUNT = 'account'
}

export enum EntityRoutes {
    ENTITY = ':entity',
    NEW = 'create',
    UPDATE_PARTIAL = 'update',
    UPDATE = 'update/:id'
}

export enum AdminRoutes {
    ADMIN= 'admin',
    DASHBOARD = 'dashboard',
    ADD_CAR_FROM_ANOTHER_SITE = 'add-car-from-other-site',
    SEND_NOTIFICATION = 'send-notification',
    EMPTY_PAGE = 'refresh'
}

export const FULL_ROUTES = {
    SIGN_IN: `/${AuthRoutes.AUTH}/${AuthRoutes.SIGN_IN}`,
    ADMIN: `/${AdminRoutes.ADMIN}`,
    ADMIN_DASHBOARD: `/${AdminRoutes.ADMIN}/${AdminRoutes.DASHBOARD}`,
    ADMIN_EMPTY_PAGE: `/${AdminRoutes.ADMIN}/${AdminRoutes.EMPTY_PAGE}`,
    ADMIN_ADD_CAR_FROM_OTHER_SITE: `/${AdminRoutes.ADMIN}/${AdminRoutes.ADD_CAR_FROM_ANOTHER_SITE}`,
    ADMIN_SEND_NOTIFICATION: `/${AdminRoutes.ADMIN}/${AdminRoutes.SEND_NOTIFICATION}`,
    ENTITY_LIST: `/${AdminRoutes.ADMIN}/${EntityRoutes.ENTITY}`,
    ENTITY_UPDATE: `/${AdminRoutes.ADMIN}/${EntityRoutes.ENTITY}/${EntityRoutes.UPDATE}`,
    ENTITY_CREATE: `/${AdminRoutes.ADMIN}/${EntityRoutes.ENTITY}/${EntityRoutes.NEW}`,
    ERROR_404: `/${ErrorsRoutes.ERROR_404}`,
    MY_ACCOUNT: `/${AdminRoutes.ADMIN}/${AccountRoutes.ACCOUNT}/${AccountRoutes.MY}`,
    CHANGE_PASSWORD: `/${AdminRoutes.ADMIN}/${AccountRoutes.ACCOUNT}/${AccountRoutes.CHANGE_PASSWORD}`,
};
