import { Component } from '@angular/core';
import { FULL_ROUTES } from '../../routes';

@Component({
  templateUrl: '500.component.html'
})
export class P500Component {

  public readonly fullRoutes = FULL_ROUTES;

  constructor() { }

}
